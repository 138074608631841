.switcher {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
  user-select: none;
}

.switcher_toggle {
  position: relative;
  width: 60px;
  height: 30px;
  background-color: #f8d538;
  border-radius: 15px;
  transition: background-color 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.switcher_slider {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 26px;
  height: 26px;
  background-color: #ffffff;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.switcher_checked {
  background-color: #dcf4f6;
}

.switcher_checked .switcher_slider {
  transform: translateX(30px);
}

.switcher_icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
