.main_section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 95px);
  background: var(--bg-color);
  color: var(--txt-color);
  overflow: hidden;
  transition: background-color 0.5s ease, color 0.5s ease;
}

.centered_content {
  text-align: center;
  padding: 20px 7rem;
  max-width: 1440px;
  margin-top: -100px;
}

.centered_heading {
  font-size: 3.5rem;
  font-weight: 900;
  margin-bottom: 1rem;
  color: var(--primary-color);
  transition: color 0.5s ease;
}

.centered_paragraph {
  font-size: 1.5rem;
  line-height: 1.8;
  margin-bottom: 2rem;
  color: var(--txt-primary-color);
  max-width: 800px;
  margin: 0 auto 2rem auto;
  transition: color 0.5s ease;
}

.action_buttons {
  display: flex;
  gap: 20px;
  justify-content: center;
}

.button {
  padding: 14px 28px;
  font-size: 1.2rem;
  font-weight: 600;
  border-radius: 30px;
  text-decoration: none;
  transition: all 0.3s ease-in-out, background-color 0.3s ease, color 0.3s ease;
  box-shadow: 0 4px 6px var(--primary-color-30);
  display: flex;
  align-items: center;
  justify-content: center;
}

.primary_button {
  background-color: var(--primary-color);
  color: var(--light-color);
  border: none;
}

.secondary_button {
  background-color: transparent;
  border: 2px solid var(--primary-color);
  color: var(--primary-color);
}

.button:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 12px var(--primary-color-30);
}

.scroll_down {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  font-size: 1rem;
  color: var(--txt-primary-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.scroll_down span {
  margin-bottom: 10px;
  font-size: 1rem;
  opacity: 0.8;
  transition: color 0.3s ease;
}

.scroll_icon {
  width: 24px;
  height: 24px;
  fill: var(--primary-color);
  animation: scroll_up_down 1.5s infinite ease-in-out;
}

@keyframes scroll_up_down {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes scroll_bounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}

@media (max-width: 1000px) {
  .centered_content {
    padding: 20px 2rem;
  }
}

@media (max-width: 750px) {
  .centered_heading {
    font-size: 2.5rem;
  }

  .centered_paragraph {
    font-size: 1.2rem;
  }

  .action_buttons {
    flex-direction: column;
    gap: 15px;
  }
}

@media (max-width: 450px) {
  .centered_heading {
    font-size: 2.4rem;
  }
  .centered_paragraph {
    font-size: 1rem;
  }
}

@media (max-width: 375px) {
  .centered_content {
    padding: 20px 1rem;
  }
}
