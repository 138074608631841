@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Rubik", sans-serif;
  text-decoration: none;
}

:root {
  --primary-color: #22333b;
  --dark-color: #1a2328;
  --light-color: #f8f8f8;

  --primary-color-30: rgba(34, 51, 59, 0.3);

  --txt-primary-color: #22333b;
  --txt-dark-color: #1a2328;
  --bg-color: #ffffff;
  --txt-color: #000000;
}

.dark-mode {
  --primary-color: #f8f8f8;
  --dark-color: #22333b;
  --light-color: #2d3b45;

  --primary-color-30: rgba(248, 248, 248, 0.3);

  --txt-primary-color: #f8f8f8;
  --txt-dark-color: #f8f8f8;
  --bg-color: #1a2328;
  --txt-color: #ffffff;
}

.hamburger-react {
  margin-left: -8px;
}
