.language_dropdown {
  position: relative;
  display: inline-block;
}

.language_selected {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 70px;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 20px;
  background-color: var(--light-color);
  cursor: pointer;
  transition: 0.3s;
}

.language_selected:hover {
  box-shadow: 0 2px 4px var(--primary-color-30);
}

.arrow {
  margin-left: 8px;
  border: solid #666;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transition: transform 0.3s ease;
}

.arrow.down {
  transform: rotate(45deg);
}

.arrow.up {
  transform: rotate(-135deg);
}

.language_menu {
  position: absolute;
  top: 110%;
  left: 0;
  width: 100%;
  margin: 0;
  padding: 5px 0;
  list-style: none;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: var(--light-color);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.3s ease;
  z-index: 1000;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.language_item {
  padding: 8px 12px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  color: var(--txt-dark-color);
}

.language_item:hover {
  background-color: var(--primary-color-30);
}

.language_item.selected {
  font-weight: bold;
  color: var(--txt-primary-color);
  background-color: var(--primary-color-30);
}

.language_label {
  font-size: 14px;
  font-weight: 500;
  color: var(--txt-dark-color);
}
