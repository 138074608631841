.navbar_main {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--bg-color);
  transition: background-color 0.5s ease, color 0.5s ease;
  position: sticky;
  top: 0;
  z-index: 100;
}

.navbar_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding: 20px 7rem;
}

.navbar_logo {
  width: 50px;
  cursor: pointer;
}

.navbar_items {
  display: flex;
  gap: 3rem;
  color: var(--txt-primary-color);
  font-weight: 500;
  font-size: 1rem;
}

.navbar_theme_mode_language {
  display: flex;
  gap: 1rem;
}

@media (max-width: 1000px) {
  .navbar_container {
    padding: 20px 2rem;
  }
}

@media (max-width: 750px) {
  .navbar_items {
    display: none;
  }
  .navbar_theme_mode_language_mode {
    display: none;
  }
}

@media (max-width: 375px) {
  .navbar_container {
    padding: 20px 1rem;
  }
}
