.hamburger_menu {
  display: none;
}

@media (max-width: 750px) {
  .hamburger_menu {
    display: block;
  }
}

.hamburger_menu_items_wrapper {
  position: fixed;
  top: 95px;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 999;
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
  padding: 40px 0 0 30px;
  background-color: var(--bg-color);
}

.hamburger_menu_items {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 20px;
}
