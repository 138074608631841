.project_idea_section {
  background-color: var(--light-color);
  color: var(--primary-color);
  padding: 50px 0;
}

.project_idea_container {
  text-align: center;
  max-width: 1440px;
  margin: 0 7rem;
}

.project_idea_title {
  font-size: 2.5rem;
  font-weight: bold;
  color: var(--primary-color);
  margin-bottom: 1.5rem;
  line-height: 1.4;
}

.project_idea_title .highlight {
  color: var(--dark-color);
  background: var(--primary-color-30);
  padding: 0 0.4rem;
  border-radius: 0.25rem;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.project_idea_description {
  font-size: 1.125rem;
  color: var(--primary-color);
  margin-bottom: 2.5rem;
  line-height: 1.6;
}

.project_idea_button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--light-color);
  color: var(--primary-color);
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  font-weight: bold;
  border: none;
  border-radius: 2rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  border: 1px solid var(--primary-color-30);
}

.project_idea_button:hover {
  background-color: var(--primary-color);
  color: var(--light-color);
  transform: scale(1.05);
}

.project_idea_button .deal {
  margin-left: 0.75rem;
  font-size: 1.5rem;
}

@media (max-width: 1000px) {
  .project_idea_container {
    margin: 0 2rem;
  }
}

@media (max-width: 768px) {
  .project_idea_title {
    font-size: 2rem;
  }

  .project_idea_description {
    font-size: 1rem;
    margin-bottom: 2rem;
  }

  .project_idea_button {
    padding: 0.65rem 1.25rem;
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .project_idea_title {
    font-size: 1.6rem;
  }

  .project_idea_description {
    font-size: 0.9rem;
    line-height: 1.4;
  }

  .project_idea_button {
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
  }
}

@media (max-width: 375px) {
  .project_idea_container {
    margin: 0 1rem;
  }

  .project_idea_title {
    font-size: 1.3rem;
  }

  .project_idea_description {
    font-size: 0.8rem;
  }
}
