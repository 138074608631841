.portfolio {
  background-color: var(--bg-color);
  color: var(--txt-color);
  padding: 50px 0;
}

.portfolio_container {
  max-width: 1440px;
  margin: 0 auto;
  padding: 20px 7rem;
}

.portfolio_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.portfolio_heading {
  font-size: 2.8rem;
  font-weight: 800;
  color: var(--txt-primary-color);
}

.portfolio_filters {
  display: flex;
  gap: 20px;
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: none;
}

.portfolio_filter {
  background-color: transparent;
  color: var(--txt-color);
  padding: 12px 25px;
  border: 1px solid var(--primary-color);
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.portfolio_filter_active,
.portfolio_filter:hover {
  background-color: var(--primary-color);
  color: var(--light-color);
}

.portfolio_grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
}

.portfolio_card {
  position: relative;
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
  transition: transform 0.4s ease, box-shadow 0.4s ease;
}

.portfolio_card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.5);
}

.portfolio_image {
  width: 100%;
  height: 300px;
  display: block;
  transition: transform 0.4s ease;
  object-fit: cover;
}

.portfolio_card:hover .portfolio_image {
  transform: scale(1.1);
}

.portfolio_overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  padding: 20px;
  z-index: 2;
  background: linear-gradient(
    160deg,
    var(--primary-color-30) -12.64%,
    rgba(2, 9, 34, 0.7) 0,
    rgba(20, 20, 21, 0.2) 94.29%
  );
  opacity: 1;
  transition: opacity 0.4s ease;
}

.portfolio_card:hover .portfolio_cta {
  opacity: 1;
}

.portfolio_title {
  font-size: 1.8rem;
  font-weight: 700;
  margin-bottom: 5px;
  color: white;
}

.portfolio_type {
  font-size: 0.8rem;
  color: white;
  margin-bottom: 15px;
  text-align: center;
}

.portfolio_cta {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: auto 0 0 auto;
}

.portfolio_view-more {
  font-size: 1rem;
  color: white;
  opacity: 0;
  transform: translateX(-20px);
  transition: transform 0.4s ease, opacity 0.4s ease;
}

.portfolio_icon {
  font-size: 2rem;
  color: white;
  transform: translateX(0);
  transition: transform 0.4s ease;
}

.portfolio_card:hover .portfolio_view-more {
  opacity: 1;
  transform: translateX(0);
}

.portfolio_card:hover .portfolio_icon {
  transform: translateX(10px);
}

@media (max-width: 1000px) {
  .portfolio_container {
    padding: 20px 2rem;
  }
}

@media (max-width: 750px) {
  .portfolio_grid {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
}

@media (max-width: 675px) {
  .portfolio_header {
    flex-direction: column;
    align-items: start;
    gap: 20px;
  }
}

@media (max-width: 520px) {
  .portfolio_filters {
    gap: 10px;
    width: 100%;
    overflow-x: scroll;
  }
  .portfolio_filter {
    padding: 10px 15px;
  }
}

@media (max-width: 450px) {
  .portfolio_heading {
    font-size: 2.4rem;
  }
}

@media (max-width: 375px) {
  .portfolio_container {
    padding: 20px 1rem;
  }
}
