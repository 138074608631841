.loader_main {
  background: var(--bg-color);
  width: 100vw;
  height: 100vh;
  margin: 0 auto;
  z-index: 9999;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.loader_main img {
  width: 110px;
  height: 110px;
  position: absolute;
  z-index: 9999;
}

.home_page_loader {
  z-index: 9999;
  width: 174px;
  height: 174px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  background: linear-gradient(0deg, #22333b 33%, #eae0d5 100%);
  animation: rotation 1s linear infinite;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.home_page_loader::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 170px;
  height: 170px;
  border-radius: 50%;
  background: var(--bg-color);
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes fadeOutAndScale {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(2);
  }
}

.loader_main.fade-out {
  animation: fadeOutAndScale 0.5s forwards;
}
