.work_process {
  background-color: var(--bg-color);
  color: var(--txt-dark-color);
  padding: 50px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.work_process_container {
  max-width: 1440px;
  padding: 20px 7rem;
  width: 100%;
  text-align: center;
}

.work_process_header {
  margin-bottom: 40px;
}

.work_process_heading {
  font-size: 2.8rem;
  font-weight: bold;
  color: var(--primary-color);
  margin-bottom: 15px;
}

.work_process_description {
  font-size: 1.2rem;
  color: var(--txt-dark-color);
  line-height: 1.6;
}

.work_process_steps {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 25px;
}

.work_process_step {
  background: var(--bg-color);
  padding: 30px 20px;
  border-radius: 12px;
  box-shadow: 0 4px 10px var(--primary-color-30);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.work_process_step:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px var(--primary-color-30);
}

.work_process_icon_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  margin: 0 auto 20px;
}

.work_process_icon {
  font-size: 32px;
}

.work_process_step_title {
  font-size: 1.4rem;
  font-weight: bold;
  color: var(--txt-dark-color);
  margin-bottom: 10px;
}

.work_process_step_description {
  font-size: 1rem;
  color: var(--txt-color);
  line-height: 1.5;
}

@media (max-width: 1000px) {
  .work_process_container {
    padding: 20px 2rem;
  }
}

@media (max-width: 768px) {
  .work_process_heading {
    font-size: 2.4rem;
  }

  .work_process_description {
    font-size: 1rem;
  }

  .work_process_step {
    padding: 20px;
  }

  .work_process_icon_wrapper {
    width: 60px;
    height: 60px;
  }

  .work_process_icon {
    font-size: 28px;
  }
}

@media (max-width: 375px) {
  .work_process_container {
    padding: 20px 1rem;
  }
}
