.about_section {
  background-color: var(--light-color);
  color: var(--txt-color);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
}

.about_section_wrapper {
  display: flex;
  flex-wrap: wrap;
  max-width: 1440px;
  gap: 40px;
  width: 100%;
  padding: 20px 7rem;
}

.about_section_content {
  flex: 1;
  min-width: 300px;
}

.about_section_heading {
  font-size: 2.8rem;
  font-weight: 800;
  margin-bottom: 20px;
  color: var(--primary-color);
}

.about_section_text {
  font-size: 1.2rem;
  line-height: 1.8;
  margin-bottom: 20px;
}

.about_section_features {
  margin-bottom: 30px;
  padding-left: 20px;
  list-style: disc;
  color: var(--txt-dark-color);
  text-align: left;
}

.about_section_features li {
  font-size: 1.1rem;
  margin-bottom: 10px;
}

.about_section_button {
  background: var(--primary-color);
  color: var(--light-color);
  padding: 12px 30px;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  border-radius: 25px;
  cursor: pointer;
}

.about_section_button:hover {
  box-shadow: 0 4px 15px var(--primary-color-30);
}

.about_section_visual {
  flex: 1;
  min-width: 300px;
  display: flex;
  justify-content: end;
  align-items: center;
  max-height: 400px;
}

.about_section_visual svg {
  max-width: 100%;
  height: auto;
}

@media (max-width: 1000px) {
  .about_section_wrapper {
    padding: 20px 2rem;
  }
}

@media (max-width: 750px) {
  .about_section_wrapper {
    flex-direction: column;
    align-items: center;
  }

  .about_section_visual {
    justify-content: center;
  }

  .about_section_content {
    text-align: center;
  }

  .about_section_button {
    margin: 0 auto;
    width: 100%;
  }

  .about_section_visual {
    max-height: 300px;
  }
}

@media (max-width: 450px) {
  .about_section_heading {
    font-size: 2.4rem;
  }
  .about_section_text {
    font-size: 1rem;
    line-height: 1.5;
  }

  .about_section_features li {
    font-size: 1rem;
  }
}

@media (max-width: 375px) {
  .about_section_wrapper {
    padding: 20px 1rem;
  }
}
